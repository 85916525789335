import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'
import { ContractedServiceRepository } from '../domain/repository/ContractedServiceRepository'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted`

export const HttpContractedServiceRepository = (authData: IAuthData): ContractedServiceRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		find: async (request, options) =>
			await FetchAPI.post(`${url}/find`, request, {
				...fetchOptions,
				...options,
			}),
		findServiceVersions: (request, options) =>
			FetchAPI.get(`${url}/${request.serviceId}/versions`, { ...fetchOptions, ...options }),
	}
}
