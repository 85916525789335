import { PetitionTrackingRepository } from 'modules/contractedService/domain/management/tracking/repository/PetitionTrackingRepository'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

const url = `${process.env.REACT_APP_URL_BACK_GSE}api/services/contracted/management/tracking`

export const HttpPetitionTrackingRepository = (authData: IAuthData): PetitionTrackingRepository => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
	}

	return {
		findAll: async (options) => await FetchAPI.get(url, { ...fetchOptions, ...options }),
		find: async (request, options) => await FetchAPI.post(url + '/find', request, { ...fetchOptions, ...options }),
		cancel: async (request, options) => await FetchAPI.post(url + '/cancel', request, { ...fetchOptions, ...options }),
	}
}
