import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useExceptionManagementColsData } from 'components/services/management/exception/table/hooks/useExceptionManagementColsData'
import { useOrsServiceExceptionFindAllQuery } from 'modules/contractedService/application/ors/exception/find/useOrsServiceExceptionFindAllQuery'
import { OrsServiceExceptionStateSet } from 'modules/contractedService/application/ors/exception/update/dto/OrsServiceExceptionUpdateStateRequest'
import { useOrsServiceExceptionUpdateStateQuery } from 'modules/contractedService/application/ors/exception/update/useOrsServiceExceptionUpdateStateQuery'
import { OrsServiceException } from 'modules/contractedService/domain/ors/exception/OrsServiceException'
import { OrsServiceExceptionRepository } from 'modules/contractedService/domain/ors/exception/repository/OrsServiceExceptionRepository'
import { OrsServiceExceptionState } from 'modules/contractedService/domain/ors/exception/state/OrsServiceExceptionState'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ColData, UfinetButton } from 'ufinet-web-components'
import { AuthContext, Authority, useTranslator } from 'ufinet-web-functions'

type HookInput = {
	orsServiceExceptionRepository: OrsServiceExceptionRepository
}

type HookOutput = {
	orsServiceExceptions: {
		all: OrsServiceException[]
		nonManaged: OrsServiceException[] | undefined
	}
	heading: {
		visible: boolean
	}
	table: {
		loading: boolean
		columns: ColData[]
		headerButtons?: JSX.Element
	}
}

function useExceptionManagementTable({ orsServiceExceptionRepository }: HookInput): HookOutput {
	const authData = useContext(AuthContext)
	const authRoles = authData.userData?.authorities || []
	const permissions = Authority.getGseExceptionPermissions(authRoles)

	const translate = useTranslator()

	const [orsServiceExceptions, setOrsServiceExceptions] = useState<OrsServiceException[]>([])
	const [nonManagedOrsServiceExceptions, setNonManagedOrsServiceExceptions] = useState<OrsServiceException[]>([])

	const [orsServiceInitialStates, setOrsServiceInitialStates] = useState<
		{ id: string; state: OrsServiceExceptionState }[]
	>([])

	const orsServiceCurrentStates = useMemo(
		() => orsServiceExceptions.map((it) => ({ id: it.service.id, state: it.exception.state })),
		[orsServiceExceptions]
	)

	const orsServiceModifiedStates = useMemo(
		() =>
			orsServiceCurrentStates.filter((currentState) =>
				orsServiceInitialStates.find(
					(initialState) => initialState.id === currentState.id && initialState.state !== currentState.state
				)
			),
		[orsServiceCurrentStates, orsServiceInitialStates]
	)

	const {
		isLoading: loadingServiceExceptions,
		isFetching: fetchingServiceExceptions,
		refetch: fetchExceptions,
	} = useOrsServiceExceptionFindAllQuery(orsServiceExceptionRepository, {
		onSuccess: (serviceExceptions) => {
			setOrsServiceExceptions(serviceExceptions)
			setOrsServiceInitialStates(serviceExceptions.map((it) => ({ id: it.service.id, state: it.exception.state })))
			setNonManagedOrsServiceExceptions(
				serviceExceptions.filter((it) => it.exception.state === OrsServiceExceptionState.EMPTY)
			)
		},
		onError: () => toast.error(translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.FIND.ERROR')),
	})

	const { isLoading: updatingServiceStates, mutate: updateServiceStates } = useOrsServiceExceptionUpdateStateQuery(
		orsServiceExceptionRepository,
		{
			onSuccess: () => {
				toast.success(translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE.UPDATE.SUCCESS'))
				fetchExceptions()
			},
			onError: () => toast.error(translate('CONTRACT.SERVICE.ORS.SERVICE.EXCEPTION.STATE.UPDATE.ERROR')),
		}
	)

	const onExceptionStateChange = useCallback(
		({
			serviceId,
			selectedState = OrsServiceExceptionState.EMPTY,
		}: {
			serviceId: string
			selectedState: OrsServiceExceptionState
		}) => {
			setOrsServiceExceptions((oldValues) =>
				oldValues.map((serviceException) => ({
					...serviceException,
					exception: {
						...serviceException.exception,
						state:
							serviceException.service.id === serviceId
								? serviceException.exception.state === selectedState
									? OrsServiceExceptionState.EMPTY
									: selectedState
								: serviceException.exception.state,
					},
				}))
			)
		},
		[]
	)

	const headerButtons = useMemo(
		() => (
			<>
				{(permissions.canWrite || permissions.canUpdate) && (
					<UfinetButton
						className="me-3"
						icon={faSave}
						onClick={() => {
							const updateSet: OrsServiceExceptionStateSet = {}
							orsServiceModifiedStates.forEach(({ id, state }) => (updateSet[id] = state))
							updateServiceStates({ updates: updateSet })
						}}
						content={translate('SAVE')}
						isDisabled={orsServiceModifiedStates.length === 0 || updatingServiceStates}
					/>
				)}
			</>
		),
		[orsServiceModifiedStates, permissions, translate, updateServiceStates, updatingServiceStates]
	)

	const { columns: tableColumns } = useExceptionManagementColsData({
		canEditState: permissions.canWrite || permissions.canUpdate,
		orsServiceExceptions,
		onExceptionStateChange,
	})

	return {
		orsServiceExceptions: {
			all: orsServiceExceptions,
			nonManaged: loadingServiceExceptions || fetchingServiceExceptions ? undefined : nonManagedOrsServiceExceptions,
		},
		heading: {
			visible: permissions.canWrite || permissions.canUpdate,
		},
		table: {
			loading: loadingServiceExceptions || fetchingServiceExceptions || updatingServiceStates,
			columns: tableColumns,
			headerButtons,
		},
	}
}

export { useExceptionManagementTable }
